.jumbotron {
height: 300px;
}

.jumbo-background {
background: url(../images/encourage2.jpg);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}

.jumbo-background h1 {
    margin: 0;
    text-align: center;
    color: #ffffff;
    font-size: 45px; }
    @media only screen and (max-width: 767px) {
      .jumbo-background h1 {
        font-size: 31px; } }

.grid-box iframe {
  padding-right: 70px;

}

/*===============blog-single css start===============*/
.blog-single {
  padding: 40px 35px 60px;
  box-shadow: 0 2px 10px 0 rgba(66, 92, 187, 0.1);
  margin-top: 100px; }
  .blog-single figure {
    position: relative; }
    .blog-single figure .single-blog-img {
      margin-bottom: 45px; }
    .blog-single figure .bookmark {
      position: absolute;
      top: 20px;
      right: 30px; }
  .blog-single .blog-single-date {
    color: #4f66bb;
    font-size: 13px;
    font-weight: 600; }
  .blog-single h1 {
    font-size: 31px;
    margin: 15px auto; }

/*===============service-video css start===============*/
.service-video {
  margin: 24px 0; }

/*===============tag css start===============*/
.tag {
  margin: 40px auto; }
  .tag h3 {
    font-size: 18px;
    margin: 70px auto 20px; }
  .tag a {
    color: #767676;
    background: #f7f7f7;
    border: 1px solid #eee;
    padding: 0 5px;
    border-radius: 3px;
    margin-right: 2px;
    margin-top: 5px;
    display: inline-block; }

/*===============blog-single-related-post css start===============*/
.blog-single-related-post h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px; }

.related-post {
  margin-bottom: 25px; }
  .related-post .media-left img {
    max-width: 105px; }
  .related-post .media-body .media-heading {
    font-size: 13px;
    color: #425cbb; }
  .related-post .media-body .post-text {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5; }
    .related-post .media-body .post-text a {
      color: #111111; }

/*===============comment css start===============*/
.comment {
  margin: 20px auto 100px;
  padding: 35px 30px 15px;
  box-shadow: 0 2px 10px 0 rgba(66, 92, 187, 0.1); }
  .comment h2 {
    font-size: 21px;
    margin: 0 auto 30px; }
  .comment .media-list .media .media-left {
    padding-right: 30px; }
  .comment .media-list .media .media-right a {
    color: #dddddd; }
    .comment .media-list .media .media-right a:hover {
      color: #3366cc; }
  .comment .media-list .media .comment-text {
    margin: 20px auto 0;
    color: #767676; }
  .comment .single-comment {
    padding-bottom: 20px; }
  .comment .single-reply {
    margin: 45px 0 30px 70px;
    padding-left: 30px;
    border-left: 3px solid #dddddd; }
    @media only screen and (max-width: 767px) {
      .comment .single-reply {
        margin: 45px 0 30px 30px; } }
  .comment .reply-form h3 {
    font-size: 21px;
    margin: 40px auto 30px; }
  .comment .reply-form .form-group {
    margin-bottom: 20px; }
    .comment .reply-form .form-group .form-control {
      height: auto;
      min-height: 50px;
      border-radius: 2px; }
    .comment .reply-form .form-group textarea {
      padding: 20px 25px; }
    .comment .reply-form .form-group button {
      margin: 5px 0;
      border-radius: 2px; }

/*===============sidebar css start===============*/
.sidebar-blog {
  margin-left: 30px;
  margin-top: 100px; }
  @media only screen and (max-width: 991px) {
    .sidebar-blog {
      margin: 70px auto 0;
      max-width: 350px; } }

.recent-post h3 {
  font-size: 18px;
  margin: 0 auto 30px; }

.sidebar-archive {
  margin: 70px auto 0; }
  .sidebar-archive h4 {
    margin: 0 0 20px; }
  .sidebar-archive ul {
    padding: 0;
    list-style-type: none; }
    .sidebar-archive ul li {
      border-bottom: 1px solid #eeeeee;
      padding: 5px 0; }
      .sidebar-archive ul li a {
        color: #3366cc; }

/*==================blog-grid section css================*/
.blog-grid {
  margin-top: 100px; }
  .blog-grid .grid-box {
    padding: 30px 30px 25px;
    box-shadow: 0 2px 10px 0 rgba(66, 92, 187, 0.1);
    margin-bottom: 30px;
    transition: all .4s ease; }
    .blog-grid .grid-box:hover {
      box-shadow: 0 2px 10px 0 rgba(66, 92, 187, 0.57);
      transition: all .4s ease; }
    .blog-grid .grid-box figure {
      position: relative; }
      .blog-grid .grid-box figure .grid-box-img {
        margin-bottom: 30px; }
      .blog-grid .grid-box figure .bookmark {
        position: absolute;
        top: 10px;
        right: 10px; }
    .blog-grid .grid-box .blog-date {
      color: #4f66bb;
      font-size: 13px;
      font-weight: 600; }
    .blog-grid .grid-box h2 {
      font-size: 21px;
      margin: 15px auto;
      line-height: 24px; }
      .blog-grid .grid-box h2 a {
        color: #000000; }
    .blog-grid .grid-box .blog-read-more {
      color: #3366cc;
      font-weight: 600;
      text-transform: uppercase; }
  .blog-grid .post-nav {
    margin: 30px auto 60px;
    min-height: 30px;
    text-align: right; }