.jumbotron {
height: 500px;
}
.jumbo-background {
background: url(../images/light-bulbs.jpg);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}

.jumbo-background h1 {
    margin: 0;
    text-align: center;
    color: #ffffff;
    font-size: 45px; }
    @media only screen and (max-width: 767px) {
      .jumbo-background h1 {
        font-size: 31px; } }

.faq-form {
    max-width: 580px;
    margin: 20px auto 0;
    }
       
.question {
  margin-top: 100px; 
 
  }
  .question h2 {
    text-align: center;
    font-size: 31px;
    margin-top: 0;
    margin-bottom: 40px; }
    .question h2 small {
      color: #333333;
      font-size: 21px; }

    .question .panel-group  {
       padding-bottom: 5px; 
       	}
      .question .panel-group .panel-heading {
        color: #ffffff;
        background: #3366cc;
        border-radius: 0;
        padding: 14px 30px; }
      .question .panel-group .panel-body {
        padding: 30px 60px; 
       border-radius: 0px;
       border: 1px solid gray; 
        }
