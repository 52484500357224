.container_profile {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.two-sided-box {
	margin-top: 30px;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 10px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-facebook {
  background: #3B5998;
  color: white;
  margin-left: 80px;
}

.social_media {}

.social_media ul > li{
   display:inline-block;
}

.container_profile ul > li {
  display:block;

}