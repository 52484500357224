/*@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");*/
@font-face {
  font-family: 'Verdana';
  src: url('./fonts/verdana.ttf');
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: Verdana, Geneva, sans-serif;
  background-color: #f9f9f9;
}

.ant-collapse-item{
  background: #ffffff;
}
.ant-collapse-content-box{
  background: #f9f9f9;
}
.list-group{
  padding: 10px 0px;
}
.list-group-item{
  background: transparent;
  border: none;
  padding-left: 0px;
  padding: 10px 0px;
}
.fa{
  margin: 0px;
  font-size: 14px !important;
}
.navbar {
  font-size: 14px !important;
  font-weight: bold;
}

.nav-link {
  border-bottom: 3px solid transparent;
}

.nav-link:hover {
  border-bottom: 3px solid #1bcfbe;
}

.floatBlock {
  margin: 0 1.81em 0 0;
}

.display-linebreak {
  white-space: pre-line;
}

.body {
  min-height: 100vh;
  position: relative;
  padding-bottom: 50px;
}

div {
  white-space: pre-line;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

hr.new {
  border: 1px solid;
}

.checkboxOptions {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  break-before: always;
  margin: 0 0 3em 0;
}

.hide {
  display: none;
}

.plus-minus {
  padding-right: 10px;
}

points {
  text-align: center;
}

.simple_button {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.heading_top {
  background: #000033;
  color: #ffffff;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 20px;
  border: 0px;
}

.label-padding {
  padding-right: 10px;
}

.perfect-moment {
  margin-top: 50px;
  background: url(../images/courage1.jpg) center center no-repeat;
  background-size: cover;
}

.perfect-moment .perfect-quote {
  text-align: center;
  padding: 94px 0;
}

.perfect-community {
  margin-top: 50px;
  background: url(../images/community_hands.jpg) center center no-repeat;
  background-size: cover;
}

.perfect-community .perfect-quote-community {
  text-align: center;
  padding: 94px 0;
}

.perfect-community .perfect-quote-community h4 {
  margin-top: 0;
  font-size: 45px;
  color: #00006f;
  font-weight: 300;
}

.perfect-moment .perfect-quote h4 {
  margin-top: 0;
  font-size: 45px;
  color: #111111;
  font-weight: 300;
}

.perfect-moment .perfect-quote p {
  font-size: 21px;
  color: #111111;
}

.heading_nav {
  /* background: #000033; */
  background: #00006f;
}

.carousel-inner img {
  width: 100%;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
}

/* custom button */

.custom-btn:enabled {
  text-transform: uppercase;
  border: 1px solid #3366cc;
  border-radius: 2px;
  background: #000033;
  /*#425cbb;*/
  color: #ffffff;
  font-size: 14px;
  padding: 19px 35px;
  display: inline-block;
  margin: 20px auto;
  font-weight: 600;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  transition: color .4s ease;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
}

.custom-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 2px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.custom-btn:hover {
  color: #3366cc;
}

.custom-btn:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.card {
  border-radius: 4px;
  box-shadow: 0 4px 43px 0 rgba(66, 92, 187, 0.2);
  width: 100px;
  text-align: center;
  margin: 0 auto;
}

.shift-right {
  margin-left: 100px;
  padding-top: 10px;
}

.two-sided-box {
  margin-top: 5px;
  /* added this because the bottom box on the home page was on top of the footer - changing the footer screws the 
   home page up if they aren't logged in */
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .two-sided-box {
    margin-top: 50px;
  }
}

.two-sided-box .two-sided-box-in {
  /* changed this padding - used to be 70. I did this for the badge table on the profile to make it fit */
  padding: 20px;
  border-radius: 2px;
 /*  box-shadow: 0 4px 43px 0 rgba(66, 92, 187, 0.2); */
 box-shadow: 0 0px 5px 0 rgba(66, 92, 187, 0.1);
 background: white;
 border: 1px solid #efefef;
  transition: all .4s ease;
}

/* .two-sided-box .two-sided-box-in:hover {
  box-shadow: 0 4px 43px 0 rgba(66, 92, 187, 0.4);
  transition: all .4s ease;
} */

@media only screen and (max-width: 991px) {
  .two-sided-box .two-sided-box-in {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .two-sided-box .two-sided-box-in {
    padding: 20px;
  }
}

.div-height-fix-flex {
  -webkit-align-items: center;
  align-items: center;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

@media only screen and (max-width: 991px) {
  .div-height-fix-flex {
    display: block;
  }
}

.service-video {
  margin: 24px 0;
}

.section-title {
  font-size: 31px;
  margin-top: 0;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 0;
}

.section-sub-title {
  font-size: 18px;
  color: #444444;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
}

footer .footer-top {
  background: #00006f;
  color: #ffffff;
  margin-top: 50px;
}

/* .bg-less-blue{
  background: #ecf4ff;
  padding: 10px 15px;
} */

.sep-heading {
  background: white;
  box-shadow: 0 0px 5px 0 rgba(66, 92, 187, 0.1);
  padding: 13px;
  border: 1px solid #efefef;
}

@media (max-width: 1399px) {}

footer .footer-top .f-top-left {
  margin-top: 50px;
}

footer .footer-top .f-top-left p {
  margin: 20px 0 27px;
}

footer .footer-top .f-top-left .subscribe label {
  font-size: 18px;
  line-height: 45px;
}

footer .footer-top .f-top-left .subscribe .form-control {
  height: auto;
  min-height: 50px;
}

footer .footer-top .f-top-left .subscribe button {
  background: #3366cc;
  text-transform: uppercase;
  min-height: 50px;
  transition: all .4s ease;
}

footer .footer-top .f-top-left .subscribe button:hover {
  background: #3366cc;
  color: #ffffff;
  transition: all .4s ease;
}

footer .footer-top .f-top-center {
  margin-top: 65px;
}

footer .footer-top .f-top-center h3 {
  color: #ffffff;
  margin: 0 0 20px;
  font-size: 18px;
}

footer .footer-top .f-top-center .popular-post {
  margin-top: 35px;
}

footer .footer-top .f-top-center .popular-post .media-left .media-object {
  margin-right: 10px;
}

footer .footer-top .f-top-center .popular-post .media-body {
  font-weight: 600;
}

footer .footer-top .f-top-center .popular-post .media-body .media-heading {
  font-size: 13px;
  color: #a0acdb;
  line-height: 1em;
  font-weight: 400;
}

footer .footer-top .f-top-center .popular-post .media-body a {
  color: #ffffff;
}

footer .footer-top .f-top-center .popular-post hr {
  margin: 23px 0;
}

footer .footer-top .f-top-center .f-center-list {
  padding: 0;
  margin-top: 22px;
}

footer .footer-top .f-top-center .f-center-list li {
  list-style-type: none;
  border-bottom: 1px solid rgba(66, 92, 187, 0.47);
}

footer .footer-top .f-top-center .f-center-list li a {
  color: #ffffff;
  line-height: 37px;
}

footer .footer-top .f-top-center .f-soc-nav {
  margin-top: 30px;
}

footer .footer-top .f-top-center .f-soc-nav a {
  margin-right: 9px;
}

@media (max-width: 1199px) and (min-width: 992px) {
  footer .footer-top .f-top-center .f-soc-nav a {
    margin-bottom: 7px;
    display: inline-block;
  }
}

footer .footer-top .f-top-center .f-soc-nav a i {
  /*color: #000000;*/
  border-radius: 50%;
  /* background: #ffffff;*/
  /* height: 40px;
          width: 40px;*/
  text-align: center;
  transition: all .4s ease;
}

footer .footer-top .f-top-center .f-soc-nav a i:hover {
  color: #ffffff;
  background: #3366cc;
}

footer .footer-top .f-top-right h3 {
  color: #ffffff;
  margin: 0 0 20px;
  font-size: 18px;
}

footer .footer-top .f-top-right .f-right-list {
  padding: 0;
  margin-top: 22px;
}

footer .footer-top .f-top-right .f-right-list li {
  list-style-type: none;
}

footer .footer-top .f-top-right .f-right-list li a {
  color: #ffffff;
  line-height: 37px;
}

footer .footer-top .f-top-right .f-soc-nav {
  margin-top: 30px;
}

footer .footer-top .f-top-right .f-soc-nav a {
  margin-right: 9px;
}

.copy {
  font-size: 14px;
  color: white;
}

@media (max-width: 1199px) and (min-width: 992px) {
  footer .footer-top .f-top-right .f-soc-nav a {
    margin-bottom: 7px;
    display: inline-block;
  }
}

footer .footer-top .f-top-right .f-soc-nav a i {
  color: #000000;
  border-radius: 50%;
  background: #ffffff;
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  transition: all .4s ease;
}

footer .footer-top .f-top-right .f-soc-nav a i:hover {
  color: #ffffff;
  background: #3366cc;
}

footer .footer-bottom {
  background: #000;
  line-height: 70px;
  font-size: 14px;
}

.Ect {
  background: #f3f3f3;
}

.GetCredits {
  background: #f3f3f3;
}

.CheckoutForm form {
  background-color: white;
}

.main, .Journal {
  background: #f3f3f3;
}

.paypal-box {
  background-color: white;
  padding: 30px;
}

.bg-white {
  padding: 30px;
  background-color: white;
}

.panel-heading h4 {
  color: white;
  font-size: 16px;
}

@media (max-width: 1399px) {
  footer .footer-bottom {
    line-height: 50px;
  }
}

footer .footer-bottom .copy {
  font-family: sans-serif;
  color: #ffffff;
  margin: 0;
}

footer .footer-bottom .copy .auth {
  color: #6075ff;
}

footer .footer-bottom .copy .back-to-top {
  text-transform: uppercase;
  text-align: center;
  z-index: 100;
  text-decoration: none;
  color: #ffffff;
  background: #000000;
  float: right;
}

footer .footer-bottom .copy .back-to-top i {
  margin-left: 10px;
}

.bottomTab {
  margin-top: 100px;
}

.bottomTab .bottomTab-in {
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(66, 92, 187, 0.1);
  transition: all .4s ease;
  min-height: 546px;
}

.bottomTab .bottomTab-in:hover {
  transition: all .4s ease;
  box-shadow: 0 2px 10px 0 rgba(66, 92, 187, 0.57);
}

.ant-collapse-item {
  padding: 10px 0px !important;
}

.Contact {
  /* background: #f3f3f3; */
}

.features-content .para {
  line-height: 1.8;
  font-weight: 400;
}

.navbar-toggler {
  border: 1px solid white;
}

.navbar-toggler-icon {
  height: 0;
  border: 1px solid white;
  margin: 6px 0px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .author-info {
    padding-top: 0px !important;
  }
  .testimonial {
    padding-bottom: 0px;
  }
  .single-block {
    padding: 20px 10px;
  }
}

.single-block h3 {
  font-size: 21px;
}

.single-block p {
  color: #444444;
}

.developer_section_nav ul {
  margin: 0;
  padding: 0;
  border-right: 1px solid #dddddd;
}

@media only screen and (max-width: 767px) {
  .developer_section_nav ul button {
    padding: 5px 40px;
  }
}

.developer_section_nav ul li {
  padding-top: 40px;
  padding-right: 50px;
  text-align: right;
  list-style-type: none;
  border-bottom: 1px solid;
  border-bottom-color: #dddddd;
  -webkit-border-image: -webkit-linear-gradient(to left, #ccc 44%, rgba(51, 51, 51, 0) 56%) 0.5 43%;
  -moz-border-image: -moz-linear-gradient(to left, #ccc 44%, rgba(51, 51, 51, 0) 56%) 0.5 43%;
  -o-border-image: -o-linear-gradient(to left, #ccc 44%, rgba(51, 51, 51, 0) 56%) 0.5 43%;
  border-image: linear-gradient(to left, #ccc 44%, rgba(51, 51, 51, 0) 56%) 0.5 43%;
}

@media only screen and (max-width: 767px) {
  .developer_section_nav ul li {
    text-align: center;
  }
}

.developer_section_nav ul li:last-child {
  padding-bottom: 40px;
  border-bottom: none;
}

.theme {
  margin-top: 30px;
}

.theme h2 {
  text-align: center;
  font-size: 31px;
  margin-top: 0;
  margin-bottom: 40px;
}

.theme .panel-group {
  padding-bottom: 5px;
}

.theme .panel-group .panel-heading {
  color: #ffffff;
  background: #0067f4;
  border-radius: 0;
  /*padding: 14px 30px; }*/
  padding: 6px 10px;
  text-align: left;
}

.theme .panel-group .panel-body {
  margin-left: 10px;
  padding: 1px 0px;
}

.theme .panel-group .panel-objective {
  margin-left: 30px;
  padding: 1px 0px;
}

.theme .panel-group .panel-objective li {
  list-style-type: none;
}